import React, { useContext, useMemo } from 'react';
import { LargeHero } from '@stereoagency/ui';
import { ILazyBlocksImage } from '@/types';
import { useCallToActions, useMediaItems } from '@/hooks';
import { LinesContext } from '@/components/LinesContext';
import { parseHtml } from '@/utils';

type Props = {
  data: {
    label?: string;
    title: string;
    subtitle?: string;
    pillars?: {
      pillar_title: string;
      pillar_description: string;
    }[]
    image?: ILazyBlocksImage;
    videoUrl?: string;
    callToActions?: number[]
    headlinevariant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'h1+' | 'h2+' | 'h3s'
  }
};

export default function NvisoLargeHero({
  data: {
    label, title, subtitle, pillars, image, videoUrl, callToActions, headlinevariant
  },
}: Props) {
  const { enableLines } = useContext(LinesContext);
  const mediaItems = useMediaItems(useMemo(() => (image ? [image.id] : []), [image]));
  const callToActionObjects = useCallToActions(callToActions ?? [], false);
  const imageProps = useMemo(() => {
    const mediaItem = image ? mediaItems.get(image.id) : null;
    if (!mediaItem || !image) return null;
    return {
      src: mediaItem?.mediaItemUrl ?? image.url ?? '',
      width: mediaItem?.mediaDetails?.width ?? 1920,
      height: mediaItem?.mediaDetails?.height ?? 1080,
      sizes: '100vw',
    };
  }, [image, mediaItems]);

  return (
    <LargeHero
      enableLines={enableLines}
      label={label}
      title={title}
      content={<div>{subtitle}</div>}
      pillars={pillars?.map((pillar) => ({
        title: pillar.pillar_title,
        description: pillar.pillar_description ? parseHtml(pillar.pillar_description) : undefined,
      })) ?? []}
      image={imageProps ?? undefined}
      videoUrl={videoUrl}
      callToActions={callToActionObjects}
      headlinevariant={headlinevariant}
    />
  );
}
